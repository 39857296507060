import { Stack, Badge, Text } from 'geist/components';
import { Link } from '@pyra/multi-zone/link';
import {
  analytics,
  AnalyticsEvent,
} from '@vercel/site-analytics/vercel-client';
import type {
  Entry,
  Excerpt,
  HighlightRange,
  TitleHighlightRange,
} from '../types';
import styles from '../search.module.css';

export function SearchResult({
  entry,
  excerpts,
  titleHighlightRanges,
  index,
  selectedIndex,
  setSelectedIndex,
  isChangelog,
  noTabIndex,
}: {
  entry: Entry;
  excerpts?: Excerpt[];
  titleHighlightRanges: TitleHighlightRange[];
  index: number;
  selectedIndex: number;
  setSelectedIndex: (i: number) => void;
  isChangelog: boolean;
  noTabIndex?: boolean;
}): JSX.Element {
  const excerpt =
    excerpts?.find((e) => e.text[0].toUpperCase() === e.text[0]) ||
    excerpts?.[0];

  function onKeyDown(e: React.KeyboardEvent<HTMLLIElement>): void {
    if (e.key === 'Enter') {
      e.currentTarget.querySelector('a')?.click();
    }
  }

  return (
    <li
      aria-selected={index === selectedIndex}
      className={styles.result}
      onKeyDown={onKeyDown}
      onMouseEnter={(): void => setSelectedIndex(index)}
      role="option"
      tabIndex={noTabIndex ? undefined : 0}
    >
      <Link
        href={entry.url}
        onClick={() => {
          analytics.track(AnalyticsEvent.CLICK_EVENT, {
            click_name: 'blog_search_result',
            click_value: entry.title,
          });
        }}
      >
        <Stack align="center" direction="row" gap={2}>
          <Highlight
            ranges={titleHighlightRanges}
            text={entry.title}
            type="title"
          />
          <Badge
            size="sm"
            variant={isChangelog ? 'amber-subtle' : 'blue-subtle'}
          >
            {isChangelog ? 'Changelog' : 'Blog'}
          </Badge>
        </Stack>
        {excerpt?.text ? (
          <Highlight
            className={styles.excerpt}
            ranges={excerpt.highlight_ranges}
            text={excerpt.text}
            type="excerpt"
          />
        ) : null}
      </Link>
    </li>
  );
}

export function Highlight({
  text,
  ranges,
  className,
  type,
}: {
  text: string;
  ranges?: HighlightRange[];
  className?: string;
  type: 'title' | 'excerpt';
}): JSX.Element {
  const textColor = type === 'excerpt' ? 'gray-900' : 'gray-1000';
  const textWeight = type === 'excerpt' ? 400 : 500;

  const parts: { str: string; highlight?: boolean }[] = [];
  // ranges.sort((a, b) => a.beginning - b.beginning);
  let prev = 0;
  if (!ranges?.length) {
    return (
      <Text as="span" className={className} variant="label-14" weight={500}>
        {text}
      </Text>
    );
  }

  for (const [beginning, end] of ranges) {
    parts.push({ str: text.slice(prev, beginning) });
    parts.push({ str: text.slice(beginning, end), highlight: true });
    prev = end;
  }
  parts.push({ str: text.slice(prev) });

  return (
    <Text
      as={type === 'excerpt' ? 'p' : 'span'}
      className={className}
      color={textColor}
      variant="label-14"
      weight={textWeight}
    >
      {parts.map((p) =>
        p.highlight ? (
          <mark className={styles.highlight} key={p.str}>
            {p.str}
          </mark>
        ) : (
          <span key={p.str}>{p.str}</span>
        ),
      )}
    </Text>
  );
}

export function NoResults({ inputValue }: { inputValue: string }): JSX.Element {
  return (
    <Text
      align="center"
      aria-selected="false"
      as="li"
      className={styles.result}
      color="gray-900"
      role="option"
      variant="label-14"
    >
      No results found for &quot;{inputValue}&quot;.
      <br />
      Try again with a different keyword.
    </Text>
  );
}
