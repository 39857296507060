'use client';

import type { KeyboardEventHandler } from 'react';
import {
  memo,
  useEffect,
  useRef,
  useState,
  useCallback,
  type JSX,
} from 'react';
import { clsx } from 'clsx';
import useSWR from 'swr';
import { Skeleton, Stack, Input, Button } from 'geist/components';
import {
  MagnifyingGlassSmall,
  Cross,
  MagnifyingGlass,
} from 'geist/new-icons/16';
import { useRouter } from '@pyra/vercel-segment/navigation';
import { useDebouncedValue } from '@pyra/hooks/use-debounce';
import {
  analytics,
  AnalyticsEvent,
} from '@vercel/site-analytics/vercel-client';
import { isIOS } from 'geist/tmp/utils';
import styles from './search.module.css';
import type { Result } from './types';
import { NoResults, SearchResult } from './results';

const fetcher = (url: string): Promise<Result[]> =>
  fetch(url).then((r) => r.json() as Promise<Result[]>);

function Search(): JSX.Element {
  const [inputValue, setInputValue] = useState('');
  const [hasFocus, setHasFocus] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const labelRef = useRef<HTMLLabelElement>(null);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const resultsRef = useRef<HTMLUListElement | null>(null);
  const router = useRouter();

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 100);

  const { data: results = [], isLoading: isSWRLoading } = useSWR(
    () =>
      debouncedSearchQuery
        ? `/api/algolia/blog/search?q=${debouncedSearchQuery}`
        : null,
    fetcher,
  );

  // Since we're debouncing, SWR isn't hit immediately, so we need to check if the user has
  // typed characters between the last search and the current render
  const isLoading = isSWRLoading || inputValue !== debouncedSearchQuery;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setExpanded(Boolean(value));
      setSelectedIndex(0);
      setInputValue(value);

      // The idea is if the search query is short/the first few letters, try to prioritize showing the user
      // results quickly to make it feel snappy. Otherwise, wait until they're done typing
      if (value.length < 5 || value.endsWith(' ')) {
        setSearchQuery(value);
      } else {
        setSearchQuery(value);
      }
    },
    [setSearchQuery],
  );

  useEffect(() => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Fix ESLint Error (#13355) */
    const close = ({ target }: any): void => {
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- TODO: Fix ESLint Error (#13355) */
      const contains = labelRef.current?.contains(target);
      if (expanded && !contains) {
        setExpanded(false);
      }
    };
    document.addEventListener('click', close);
    return (): void => document.removeEventListener('click', close);
  }, [expanded]);

  const onWindowKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === '/' && searchRef.current) {
      // Input is already focused
      if (document.activeElement === searchRef.current) {
        return;
      }

      // Only focus the search input if nothing else is
      if (document.activeElement !== document.body) {
        return;
      }

      e.preventDefault();
      searchRef.current.focus();
    }
  }, []);

  // keep the selected item in view
  useEffect(() => {
    if (expanded && resultsRef.current) {
      const selected = resultsRef.current.children[selectedIndex];

      selected.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [expanded, selectedIndex]);

  useEffect(() => {
    window.addEventListener('keydown', onWindowKeyDown);

    return (): void => {
      window.removeEventListener('keydown', onWindowKeyDown);
    };
  }, [onWindowKeyDown]);

  const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e): void => {
    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();
        setSelectedIndex(Math.min(results.length - 1, selectedIndex + 1));
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        setSelectedIndex(Math.max(0, selectedIndex - 1));
        break;
      }
      case 'Enter': {
        const result = results[selectedIndex];
        /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- TODO: Fix ESLint Error (#13355) */
        if (result) {
          router.prefetch(result.entry.url);
          router.push(result.entry.url);
        }
        break;
      }
    }
  };

  const onInputFocus = (): void => {
    setHasFocus(true);
    /* eslint-disable-next-line no-implicit-coercion -- TODO: Fix ESLint Error (#13355) */
    setExpanded(!!inputValue);

    analytics.track(AnalyticsEvent.CLICK_EVENT, {
      click_name: 'blog_interaction',
      click_value: 'search',
    });
  };

  const [showMobileSearch, setShowMobileSearch] = useState(false);

  return (
    <div
      className={clsx(
        styles.container,
        showMobileSearch && styles['mobile-search-open'],
      )}
      data-mobile-search-open={showMobileSearch}
    >
      <Button
        aria-label="Open blog search menu"
        className={styles['mobile-button']}
        onClick={() => {
          setShowMobileSearch(true);

          setTimeout(() => {
            searchRef.current?.focus();
          }, 10);
        }}
        shape="circle"
        size="small"
        svgOnly
        type="secondary"
      >
        <MagnifyingGlass />
      </Button>

      <label
        aria-haspopup="listbox"
        className={clsx(styles.label, {
          [styles.focused]: hasFocus,
          [styles['hide-on-mobile']]: !showMobileSearch,
        })}
        ref={labelRef}
      >
        <span className="geist-sr-only" id="search-posts">
          Search posts
        </span>
        <Input
          aria-autocomplete="list"
          aria-controls="index-blog-output"
          aria-labelledby="search-posts"
          autoComplete="off"
          className={styles.input}
          onBlur={(): void => setHasFocus(false)}
          onChange={handleChange}
          onFocus={onInputFocus}
          onKeyDown={onInputKeyDown}
          placeholder="Search posts"
          prefix={<MagnifyingGlassSmall />}
          prefixStyling={false}
          ref={searchRef}
          size="small"
          typeName="search"
          value={inputValue}
        />

        {inputValue || showMobileSearch ? (
          <Button
            aria-label="Clear search"
            className={styles.close}
            onClick={() => {
              searchRef.current?.focus();
              setInputValue('');
              setExpanded(false);
              setShowMobileSearch(false);
            }}
            svgOnly
            typeName="button"
            variant="unstyled"
          >
            <Cross color="var(--ds-gray-700)" />
          </Button>
        ) : null}
      </label>

      <ul
        aria-live="polite"
        className={clsx(styles.results, {
          [styles.isIOS]: isIOS(),
          [styles.isIOSFocused]: isIOS() && hasFocus,
        })}
        id="index-blog-output"
        ref={resultsRef}
        role="listbox"
        style={{ display: expanded ? '' : 'none' }}
        tabIndex={-1}
      >
        {isLoading ? <Loading /> : null}
        {!isLoading && !results.length ? (
          <NoResults inputValue={inputValue} />
        ) : null}
        {!isLoading && results.length
          ? results.map((r, i) => (
              <SearchResult
                entry={r.entry}
                excerpts={r.excerpts}
                index={i}
                isChangelog={r.isChangelog}
                /* eslint-disable-next-line react/no-array-index-key -- TODO: Fix ESLint Error (#13355) */
                key={i}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                titleHighlightRanges={r.titleHighlightRanges}
              />
            ))
          : null}
      </ul>
    </div>
  );
}

function ItemSkeleton(): JSX.Element {
  return (
    <li aria-selected="false" className={styles.result} role="option">
      <Stack direction="row" gap={2}>
        <Skeleton width="45%" />
        <Skeleton width="10%" />
      </Stack>
      <div className={styles.excerpt}>
        <Skeleton width="95%" />
      </div>
    </li>
  );
}

function Loading(): JSX.Element {
  return (
    <>
      {[0, 1].map((i) => (
        <ItemSkeleton key={i} />
      ))}
    </>
  );
}

/* eslint-disable-next-line import/no-default-export -- TODO: Fix ESLint Error (#13355) */
export default memo(Search);
