'use client';

import { clsx } from 'clsx';
import {
  Menu,
  MenuButton,
  MenuContainer,
  MenuLink,
  Text,
} from 'geist/components';
import { Link } from '@pyra/multi-zone/link';
import { ButtonLink } from '@pyra/multi-zone/link';
import { usePathname } from '@pyra/vercel-segment/navigation';
import { ChevronDownSmall, Rss } from 'geist/new-icons/16';
import {
  analytics,
  AnalyticsEvent,
} from '@vercel/site-analytics/vercel-client';
import Search from './search';
import styles from './blog-navigation.module.css';

export function BlogNavigation(): JSX.Element {
  const pathname = usePathname();

  return (
    <nav className={styles.nav}>
      <MobileLinksMenu currentPath={pathname} />
      <ul className={styles.links}>
        {LINKS.map(({ href, key }) => {
          const isActive = pathname === href;

          return (
            <Text
              as="li"
              className={clsx(isActive && styles.active)}
              color={isActive ? 'background-100' : 'gray-1000'}
              key={key}
              variant="label-14"
            >
              <Link
                aria-current={isActive ? 'page' : undefined}
                href={href}
                onClick={() => {
                  analytics.track(AnalyticsEvent.CLICK_EVENT, {
                    click_name: 'blog_nav',
                    click_value: key,
                  });
                }}
              >
                {key}
              </Link>
            </Text>
          );
        })}
      </ul>
      <div className={styles.right}>
        <Search />
        <ButtonLink
          aria-label="RSS feed"
          className={styles.button}
          href="/atom"
          shape="circle"
          size="small"
          svgOnly
          type="secondary"
        >
          <Rss className={styles.rss} color="var(--ds-gray-900)" />
        </ButtonLink>
      </div>
    </nav>
  );
}

function MobileLinksMenu({
  currentPath,
}: {
  currentPath: string | null;
}): JSX.Element {
  const currentLink = LINKS.find((link) => link.href === currentPath);

  return (
    <MenuContainer>
      <MenuButton
        aria-label="Open category filter menu"
        className={styles.toggle}
        size="small"
        suffix={<ChevronDownSmall className={styles.chevron} size={16} />}
      >
        {currentLink?.key || 'Links'}
      </MenuButton>
      <Menu className={styles['mobile-menu']}>
        {LINKS.map(({ key, href }) => (
          <MenuLink
            href={href}
            key={key}
            onClick={() => {
              analytics.track(AnalyticsEvent.CLICK_EVENT, {
                click_name: 'blog_nav',
                click_value: key,
              });
            }}
          >
            {key}
          </MenuLink>
        ))}
      </Menu>
    </MenuContainer>
  );
}

const blogCategory = (category: string): string => `/blog/category/${category}`;

const LINKS = [
  {
    key: 'All Posts',
    href: '/blog',
  },
  {
    key: 'Engineering',
    href: blogCategory('engineering'),
  },
  {
    key: 'Community',
    href: blogCategory('community'),
  },
  {
    key: 'Company News',
    href: blogCategory('company-news'),
  },
  {
    key: 'Customers',
    href: blogCategory('customers'),
  },
  {
    key: 'Changelog',
    href: '/changelog',
  },
  {
    key: 'Press',
    href: '/press',
  },
];
