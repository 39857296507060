import { useCallback, useEffect, useRef, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
/**
 * Returns a debounced callback.
 *
 * @param callback - The callback to debounce.
 * @param wait - The time to wait for the debounce. Defaults to `0`.
 * @returns A debounced callback.
 */
export function useDebouncedCallback<T extends unknown[]>(
  callback: (...args: T) => void,
  wait = 0,
): (...args: T) => void {
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearCurrentTimeout = (): void => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  };

  useEffect(() => {
    return () => clearCurrentTimeout();
  }, []);

  return function debouncedCallback(...args: T) {
    clearCurrentTimeout();
    timeout.current = setTimeout(() => {
      callback(...args);
    }, wait);
  };
}

/**
 * Returns a debounced value.
 *
 * @param value - The value to debounce.
 * @param wait - The time to wait for the debounce. Defaults to `0`.
 * @returns A debounced value.
 */
export function useDebouncedValue<T>(value: T, wait = 0): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => setDebouncedValue(value), wait);
    return () => clearTimeout(timeout);
  }, [value, wait]);

  return debouncedValue;
}

/**
 * For more control over a debounce state hook, gives you access to the usual
 * [debouncedState, setStateDebounced], but adds on helpers.
 *
 * @param valIn - The initial state value
 * @param delay - The debounce delay
 * @returns [
 *    debouncedState,
 *    setStateDebounced,
 *    \{
 *      currentValue: (value non-debounced)
 *      setValueImmediate: (set both debounce/non-debounce immediately)
 *    \}
 *  ]
 */
export function useDebounceState<A>(
  valIn: A,
  delay: number,
): readonly [
  A,
  Dispatch<SetStateAction<A>>,
  { readonly currentValue: A; readonly setValueImmediate: (next: A) => void },
] {
  const [value, setValue] = useState(valIn);
  const [valueDebounced, setValueDebounced] = useDebounceWithSetter(
    value,
    delay,
  );

  return [
    valueDebounced,
    setValue,
    {
      currentValue: value,
      setValueImmediate: useCallback(
        (next) => {
          setValue(next);

          setValueDebounced(next);
        },
        [setValue, setValueDebounced],
      ),
    },
  ] as const;
}

export function useDebounceWithSetter<A>(
  value: A,
  delay: number,
): readonly [A, Dispatch<SetStateAction<A>>] {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return (): void => clearTimeout(timeout);
  }, [value, setDebouncedValue, delay]);

  return [debouncedValue, setDebouncedValue] as const;
}
